import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export const SectionHeading: React.FC<any> = ({ children }) => {
  return (
    <Box sx={{ width: "100%", maxWidth: "md", mt: 6 }}>
      <Divider
        sx={{
          "&::before, &::after": {
            borderColor: "var(--jumper-red-light)",
          },
        }}
      >
        <Typography variant="h3" textAlign="center">
          {children}
        </Typography>
      </Divider>
    </Box>
  );
};

export default SectionHeading;
