import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import SectionHeading from "../../components/SectionHeading";

const frameworksAndLibraries = [
  "Android",
  "Flutter",
  "React",
  ".NET",
  "Material UI",
];

const languages = [
  "English (native)",
  "German (native)",
  "Java",
  "Kotlin",
  "Dart",
  "Typescript",
  "JavaScript",
  "C#",
];

const otherSkills = [
  "Git",
  "Firebase",
  "Azure",
  "Unit testing",
  "Clean Architecture",
  "Agile",
];

function Skills() {
  return (
    <Grid container justifyContent="center">
      <Box sx={{ width: "100%" }}>
        <Stack>
          <Box
            sx={{ backgroundColor: "var(--card-background)", width: "100%" }}
          >
            <Stack
              spacing={2}
              sx={{ padding: 4, maxWidth: "md", margin: "auto" }}
            >
              <SectionHeading>Skills</SectionHeading>

              <Typography variant="body1" color="GrayText" textAlign="center">
                To ensure quality and security, I use <b>unit testing</b> and
                clean architecture to build apps.
                <br />I have worked in several teams where we practiced{" "}
                <b>Agile</b> and used <b>Scrum</b> for efficient handling of the
                requested features and development. To manage code we used{" "}
                <b>Git</b> or <b>Devops</b> with automation of testing and
                deploying. In my latest personal projects I implement automation
                with Github Actions as well.
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "var(--jumper-red-light)",
              margin: "auto",
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              spacing={2}
              sx={{
                width: "100%",

                padding: 4,
                maxWidth: "md",
                margin: "auto",
              }}
            >
              <Grid item md={4} xs={12}>
                <SkillSection
                  title="Frameworks & Libraries"
                  skills={frameworksAndLibraries}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SkillSection
                  title="Languages"
                  skills={languages}
                  comment="I speak:"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SkillSection title="Other" skills={otherSkills} />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

export default Skills;

interface SkillSectionProps {
  title: string;
  comment?: string;
  skills: string[];
}

export const SkillSection: React.FC<SkillSectionProps> = ({
  title,
  comment,
  skills,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        margin: "auto",
        boxShadow: "none",
        backgroundColor: "var(--card-background)",
        maxWidth: 300,
      }}
    >
      <CardContent>
        <Stack spacing={1}>
          <Typography variant="h5">{title}</Typography>
          {comment && <Typography variant="subtitle1">{comment}</Typography>}
          <ul>
            <Typography variant="body1" color="text.secondary">
              {skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </Typography>
          </ul>
        </Stack>
      </CardContent>
    </Card>
  );
};
