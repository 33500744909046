import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { NavLink, To, useLocation } from "react-router-dom";
import logoSrc from "../res/logo192.png";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const title = "Jonah Sebright";

const drawerWidth = 240;
const navItems = ["Projects", "About", "Contact"];

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  let location = useLocation();
  console.log("location: ", location);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerItem = (page: string, to: To) => (
    <NavLink key={page} to={to} className="No-line-link-on-light">
      <ListItem disablePadding selected={location.pathname === "/" + to}>
        <ListItemButton sx={{ textAlign: "center" }}>
          <ListItemText primary={page} />
        </ListItemButton>
      </ListItem>
    </NavLink>
  );

  const drawer = (
    <Stack
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", pt: 2 }}
      direction="column"
      alignItems="stretch"
    >
      <Avatar
        sx={{
          display: { xs: "flex", sm: "none" },
          mx: "auto",
          width: 48,
          height: 48,
        }}
        alt="Jonah Sebright Logo"
        src={logoSrc}
      />
      <Typography variant="h6" sx={{ my: 2 }}>
        {title}
      </Typography>
      <Divider sx={{ width: "100%" }} />
      <List>
        {drawerItem("Home", "")}
        {navItems.map((page) => drawerItem(page, page.toLowerCase()))}
      </List>
    </Stack>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        style={{
          background:
            "linear-gradient(90deg, rgba(149,72,84,1) 0%, rgba(35,28,62,1) 100%)",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar
            sx={{ display: { xs: "none", sm: "flex", md: "flex" }, mr: 1 }}
            alt="Jonah Sebright Logo"
            src={logoSrc}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "No-line-link-active" : "No-line-link"
              }
            >
              {title}
            </NavLink>
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((page) => (
              <NavLink
                key={page}
                to={page.toLowerCase()}
                className={({ isActive }) =>
                  isActive ? "No-line-link-active" : "No-line-link"
                }
              >
                <Typography
                  key={page}
                  sx={{ display: "inline", mx: 1 }}
                  variant="button"
                >
                  {page}
                </Typography>
              </NavLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
