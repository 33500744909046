import { Stack, Typography } from "@mui/material";
import React from "react";

interface PageHeadingProps {
  title: string;
  description: string;
}

const PageHeading: React.FC<PageHeadingProps> = ({ title, description }) => {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      sx={{
        py: 8,
        px: 4,
        width: "100%",
        background:
          "linear-gradient(90deg, rgba(252,232,227,1) 0%, rgba(235,235,235,1) 100%)",
      }}
    >
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1" sx={{ maxWidth: "md", px: 4 }}>
        {description}
      </Typography>
    </Stack>
  );
};

export default PageHeading;
