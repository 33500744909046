import { Box, Stack } from '@mui/material'
import { DetailsTable } from '../../components/DetailsTable'
import PageHeading from '../../components/PageHeading'
import { linkedInProfile } from '../../constants'

function Contact() {
	return (
		<Stack alignItems="center" spacing={8}>
			<PageHeading
				title="Contact"
				description="To hire for a project or ask any questions..."
			/>
			<Box sx={{ minWidth: 'xs', maxWidth: 'md', padding: 2 }}>
				<DetailsTable
					details={[
						{
							title: 'Mail',
							value: (
								<a href="mailto:contact@jonahsebright.com">
									contact@jonahsebright.com
								</a>
							),
						},
						{
							title: 'LinkedIn',
							value: (
								<a href={linkedInProfile} target="_blank" rel="noreferrer">
									My Profile
								</a>
							),
						},
					]}
				/>
			</Box>
		</Stack>
	)
}

export default Contact
