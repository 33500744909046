import { Android, CloudCircle, PhoneIphone, Web } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  createTheme,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SectionHeading from "../../components/SectionHeading";

interface OfferItem {
  title: String;
  content: JSX.Element;
  icon: JSX.Element;
}

const offers: OfferItem[] = [
  {
    title: "Android App",
    content: (
      <>
        <Typography variant="body1" color="text.secondary">
          I develop <b>native</b> mobile apps for <b>Android</b> with{" "}
          <b>
            <a
              href="https://material.io/design"
              target="_blank"
              rel="noreferrer"
            >
              Material Design
            </a>
          </b>
          . The programming languages can be either <b>Java</b> or <b>Kotlin</b>
          .
          <br />
          <br />
        </Typography>
        <Typography color="text.primary">*** BONUS ***</Typography>
        <ul className="Star-bullet-list">
          <Typography variant="body1" color="text.secondary">
            <li>
              The App can be connected to a <b>Firebase</b> project
              (authentication, database &{" "}
              <a href="https://firebase.com" target="_blank" rel="noreferrer">
                more
              </a>
              ).
            </li>
            <li>
              <b>Deploying</b> to the <b>PlayStore</b> can be setup to happen{" "}
              <b>automatically</b> when a new version of the app is finished
              (Continuous Integration).
            </li>
          </Typography>
        </ul>
      </>
    ),
    icon: <Android fontSize="large" />,
  },
  {
    title: "Mobile App",
    content: (
      <>
        <Typography variant="body1" color="text.secondary">
          I develop mobile apps for <b>Android and iOS</b> with <b>Flutter</b>{" "}
          (a Cross-Platform tool which allows building for both platforms at the
          same time). The app can also be easily built and adapted for the{" "}
          <b>web</b> if required.
        </Typography>
        <br />
        <br />
        <Typography color="text.primary">*** BONUS ***</Typography>
        <ul className="Star-bullet-list">
          <Typography variant="body1" color="text.secondary">
            <li>
              The App can be connected to a <b>Firebase</b> project
              (authentication, database &{" "}
              <a href="https://firebase.com" target="_blank" rel="noreferrer">
                more
              </a>
              ).
            </li>
            <li>
              <b>Automatic deploying</b> to the <b>Play & App Store</b>
            </li>
          </Typography>
        </ul>
      </>
    ),
    icon: <PhoneIphone fontSize="large" />,
  },
  {
    title: "Web App",
    content: (
      <>
        <Typography variant="body1" color="text.secondary">
          {" "}
          I develop web apps with <b>React</b> and <b>Typescript</b>. For
          beautiful and recommended design I use <b>Material-UI</b>.
          <br />
          <br />
        </Typography>
        <Typography color="text.primary">*** BONUS ***</Typography>
        <ul className="Star-bullet-list">
          <Typography variant="body1" color="text.secondary">
            <li>
              The App can be connected to a <b>Firebase</b> project.
            </li>
            <li>
              I also manage <b>Hosting</b> the webpage on your desired domain.
            </li>
            <li> Responsive design (for different screen sizes)</li>
          </Typography>
        </ul>
      </>
    ),
    icon: <Web fontSize="large" />,
  },
  {
    title: "Firebase Backend",
    content: (
      <Typography variant="body1" color="text.secondary">
        I develop backend functionality with Firebase. This includes setting up
        and managing a <b>secure database</b>, adding <b>authentication</b> to
        an app, writing <b>cloud functions and APIs</b> for web apps,
        implementing remote config and more. More info on what services Firebase
        offers{" "}
        <a href="https://firebase.com" target="_blank" rel="noreferrer">
          here
        </a>
        .
      </Typography>
    ),
    icon: (
      <Avatar
        sx={{ height: 32, width: 32, backgroundColor: "black" }}
        alt="Firebase Logo"
        src="https://www.gstatic.com/devrel-devsite/prod/v99fd0c828fa12c8e4ac5a683d534aa207353ab3b49583792c0a838c031276d00/firebase/images/touchicon-180.png"
      />
    ),
  },
  {
    title: "ASP.NET Backend",
    content: (
      <Typography variant="body1" color="text.secondary">
        I develop backend <b>APIs</b> with the <b>.NET</b> framework in{" "}
        <b>C#</b>. I can handle requests to (e.g. SQL) <b>database</b>, include
        role-based <b>authentication</b> and more.
      </Typography>
    ),
    icon: <CloudCircle fontSize="large" />,
  },
  /*{
    title: "Analysis & Recommendation",
    content: (
      <Typography variant="body1" color="text.secondary">
        I 
      </Typography>
    ),
    icon: <CloudCircle fontSize="large" />,
  },
  */
];

const rowSpacing = { xs: 4, sm: 4, md: 6 };
const columnSpacing = { xs: 1, sm: 2, md: 4 };

function Offers() {
  const [selectedOffer, setSelectedOffer] = useState(0);

  const buildOffer = (item: OfferItem, index: number) => {
    const selected = selectedOffer === index;

    return (
      <Card
        sx={{
          height: "100%",
          margin: "auto",
          backgroundColor: selected
            ? "var(--color-primary)"
            : "var(--card-background)",
          boxShadow: "none",
          border: "10px solid var(--jumper-red-light)",
        }}
      >
        <CardActionArea
          onClick={() => setSelectedOffer(index)}
          sx={{
            height: "100%",
          }}
        >
          <CardContent
            className={selected ? "Dark-card" : ""}
            sx={{
              height: "100%",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="start"
              sx={{
                color: selected ? "#fff" : "black",
              }}
            >
              <Typography gutterBottom variant="h5" component="div">
                {item.title}
              </Typography>
              {item.icon}
            </Stack>
            {selected ? (
              <ThemeProvider
                theme={createTheme({
                  palette: {
                    text: {
                      primary: selected
                        ? "var(--link-on-dark-color)"
                        : "var(--color-primary)",
                      secondary: selected ? "#fff" : "black",
                    },
                  },
                })}
              >
                {item.content}
              </ThemeProvider>
            ) : (
              item.content
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <Box padding={4}>
      <Stack spacing={2} alignItems="center">
        <SectionHeading>What I Offer</SectionHeading>

        <Grid
          container
          justifyContent="center"
          rowSpacing={rowSpacing}
          columnSpacing={columnSpacing}
          alignItems="stretch"
          maxWidth="lg"
          sx={{ pr: 1 }}
        >
          {offers.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
              {buildOffer(item, index)}
            </Grid>
          ))}
          {offers.length % 2 === 1 && (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}></Grid>
          )}
        </Grid>
      </Stack>
    </Box>
  );
}

export default Offers;
