import React from "react";
import "./App.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  Toolbar,
  Box,
  GlobalStyles,
} from "@mui/material";
import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./footer/Footer";

/**
 * Font
 * 
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
 */

let theme = createTheme({
  palette: {
    primary: {
      main: "#231c3e",
    },
  },
  typography: {
    fontFamily: "Raleway",
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          body: {},
        }}
      />
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>
        <Toolbar />
        <Box sx={{ minHeight: "80vh" }}>
          <Outlet />
        </Box>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
