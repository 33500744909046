import { Box, Stack, Typography } from "@mui/material";
import PageHeading from "../../components/PageHeading";

function About() {
  return (
    <Stack alignItems="center" spacing={8}>
      <PageHeading title="About" description="Me, myself and I" />
      <Box sx={{ minWidth: "xs", maxWidth: "md", p: 2 }}>
        <Typography variant="body1" color="text.secondary">
          Solving puzzles and tackling challenges has always been a natural
          pleasure and talent of mine. It ranges from enjoying wild board games
          to dry mathematics. I find logical connections beautiful and a proof
          manifested by a string of logical arguments is truly satisfying
          (probably because it reveals a truth that you can really trust, unlike
          so many things in this world).
          <br />
          For that reason, software development took my interest as soon as I
          learned enough about it. Additionally to the cognitive challenge it
          provides, it offers great room for creativity and allows you to create
          almost anything. That is what drove me to develop my first ever app
          which, many hours later, became GradeX. I had the tools to build a
          Grade-Management-App with all the features I imagined.
          <br />
          Apart from having intellectual fun, I absolutely enjoy playing
          Badminton which can take my mind off things and is an amazing sport
          and can highly recommend to anyone. It requires skill, contains lots
          of tactics and makes you do cardio exercise without noticing. And its
          mental game is incredible, as you have an opponent who defines your
          next move and vice-versa.
        </Typography>
      </Box>
    </Stack>
  );
}

export default About;
